.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-message {
    margin: 20px 20px;
}

.MyButton
{
    margin: 50px 20px;
}

.MyButton button
{
    padding: 20px 50px;
    font-size: 1.2em;
    border-radius: 10px;
    background-color: #000;
    color: #61dafb;
    border: solid 5px white;
}
